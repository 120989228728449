import * as React from "react"
import '../styles/global.scss';
import Layout from "../components/layout";



export default class PaymentTremsPage extends React.Component {
    public render() {
        return (
            <Layout isHomePage={false}>
                <div className={'row mt-4'}>
                    <div className={'col-12'}>

                        <h2>Regulamin opłat platformy i aplikacji „LOGGY”</h2>

                        <h3>I. Postanowienia ogólne</h3>

                        <p>1. Regulamin opłat zawiera opis rodzajów Pakietów Platformy i Aplikacji Loggy,
                            wraz z cennikiem i zasadami płatności.</p>

                        <p>2. W zakresie nieuregulowanym Regulaminem opłat stosuje się Regulamin ogólny
                            korzystania przez Licencjobiorcę z platformy „LOGGY”.</p>

                        <p>3. Licencjobiorca oświadcza, że zapoznał się z Regulaminem ogólnym i akceptuje
                            jego warunki.</p>

                        <p>4. Zmiana Regulaminu opłat nie stanowi zmiany regulaminu ogólnego.</p>

                        <h3>II. Pakiety</h3>

                        <p>W ramach funkcjonowania platformy i aplikacji LOGGY wyróżnia się następujące
                            Pakiety:
                            <ul>

                                <li>
                                    <p>a) Free – uprawniający do korzystania ze wszystkich dostępnych
                                        funkcjonalności
                                        Pakietu, przez czas nieokreślony w zakresie umożliwiającym korzystanie
                                        z Platformy Loggy oraz z Aplikacji Loggy na maksymalnie 5 urządzeniach
                                        elektronicznych pozwalających na przetwarzanie, odbieranie oraz
                                        wysyłanie
                                        danych takich jak: smartfon, tablet, telefon komórkowy,</p>

                                    <p>- Pakiet w opcji Free jest darmowy na czas nieokreślony,</p>
                                </li>

                                <li>
                                    <p>a) Basic – uprawniający do korzystania ze wszystkich dostępnych
                                        funkcjonalności
                                        Pakietu, przez okres 1 miesiąca, w zakresie umożliwiającym korzystanie
                                        z Platformy Loggy oraz z Aplikacji Loggy na maksymalnie 10 urządzeniach
                                        elektronicznych pozwalających na przetwarzanie, odbieranie oraz
                                        wysyłanie
                                        danych takich jak: smartfon, tablet, telefon komórkowy,</p>

                                    <p>- cena miesięcznego abonamentu Pakietu w opcji Basic wynosi 199 zł netto,
                                        płatne z góry, przy akceptacji Regulaminu ogólnego, za pomocą systemu
                                        płatności przelewy24.</p>

                                    <p>- Faktura elektroniczna w rozumieniu art. 2 pkt 31 i 32 USTAWY z dnia 11
                                        marca
                                        2004 r. o podatku od towarów i usług (Dz.U. 2004 Nr 54 poz. 535 ze zm.)
                                        generowana jest w ciągu 24 godzin od momentu płatności i wysyłana na
                                        adres
                                        mail wskazany przez Licencjobiorcę.</p>
                                </li>

                                <li><p>b) Business – uprawniający do korzystania ze wszystkich dostępnych
                                    funkcjonalności Pakietu, przez okres 1 miesiąca, w zakresie umożliwiającym
                                    korzystanie z Platformy Loggy oraz z Aplikacji Loggy na ilości od 11 do 50
                                    urządzeń elektronicznych pozwalających na przetwarzanie, odbieranie oraz
                                    wysyłanie danych takich jak: smartfon, tablet, telefon komórkowy,</p>

                                    <p>- cena miesięcznego abonamentu Pakietu w opcji Business wynosi 499 zł
                                        netto,
                                        płatne z góry, przy akceptacji Regulaminu ogólnego, za pomocą systemu
                                        płatności przelewy24.</p>

                                    <p>- Faktura elektroniczna w rozumieniu art. 2 pkt 31 i 32 USTAWY z dnia 11
                                        marca
                                        2004 r. o podatku od towarów i usług (Dz.U. 2004 Nr 54 poz. 535 ze zm.)
                                        generowana jest w ciągu 24 godzin od momentu płatności i wysyłana na
                                        adres
                                        mail wskazany przez Licencjobiorcę.</p>
                                </li>
                                {/*<li>*/}
                                    {/*<p>c) Max - uprawniający do korzystania ze wszystkich dostępnych*/}
                                        {/*funkcjonalności Pakietu, przez okres 1 miesiąca, w zakresie*/}
                                        {/*umożliwiającym*/}
                                        {/*korzystanie z*/}
                                        {/*Platformy Loggy oraz z Aplikacji Loggy na ilości od 51 do 100 urządzeń*/}
                                        {/*elektronicznych pozwalających na przetwarzanie, odbieranie oraz*/}
                                        {/*wysyłanie*/}
                                        {/*danych takich jak: smartfon, tablet, telefon komórkowy,</p>*/}

                                    {/*<p>- cena miesięcznego abonamentu Pakietu w opcji Max wynosi 1000 zł netto,*/}
                                        {/*płatne z góry, przy akceptacji Regulaminu ogólnego, za pomocą systemu*/}
                                        {/*płatności przelewy24.</p>*/}
                                    {/*<p>- Faktura elektroniczna w rozumieniu art. 2 pkt 31 i 32 USTAWY z dnia 11*/}
                                        {/*marca*/}
                                        {/*2004 r. o podatku od towarów i usług (Dz.U. 2004 Nr 54 poz. 535 ze zm.)*/}
                                        {/*generowana jest w ciągu 24 godzin od momentu płatności i wysyłana na*/}
                                        {/*adres*/}
                                        {/*mail wskazany przez Licencjobiorcę.</p>*/}
                                {/*</li>*/}
                            </ul></p>

                        <h3>III. Postanowienia końcowe</h3>
                        <p>1. Niniejszy Regulamin wchodzi w życie z dniem 22.06.2020</p>
                    </div>

                </div>
            </Layout>
        )
    }
}
